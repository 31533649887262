import React, { useContext, useEffect, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { PPASelectFormField } from '../../../../PPAInsights/PPAInsightFilterSection/PPASelectFormField';
import { AppContext } from '../../../../../../Context/AppContext';
import { reconFilterOptions } from '../../../../../../api/services';

export const ReconDataSourceFilter = () => {
  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;
  const { state: appDataState } = useContext(AppContext).appData;

  // Hardcoded options
  let options: string[];

  switch (appDataState.userRegion.toLowerCase()) {
    case 'americas':
      options = ['AM_NG', 'AM_PW'];
      break;
    case 'europe':
      options = ['EU_ALIGN', 'EU_GROUP'];
      break;
    case 'asia':
      options = ['AS_PW'];
      break;
    case 'australia':
      options = ['AU_NG'];
      break;
    default:
      options = ['AM_NG', 'AM_PW', 'EU_ALIGN', 'EU_GROUP', 'AS_PW', 'AU_NG'];
      break;
  }

  const [dataSourceSelected, setDataSourceSelected] = useState(options[0]);
  const [generatedData, setGeneratedData] = useState('');
  useEffect(() => {
    // call the function if the options is set to one value while logging in, this is for user with specific region
    if (options.length === 1) {
      reconDispatch({ type: 'setReconRegion', payload: dataSourceSelected });
      let tradeType = 'PW';
      let transactionType = 'BUY';
      if (dataSourceSelected === 'AM_NG' || dataSourceSelected === 'AU_NG') {
        tradeType = 'NG';
      }

      if (dataSourceSelected === 'EU_GROUP') {
        transactionType = 'NET';
      }

      const payload = {
        ...reconState.ReconfilteredValues,
        dataSource: dataSourceSelected,
        tradeType,
        transactionType,
      };
      reconDispatch({ type: 'setReconFilteredValues', payload });
    } else {
      const payload = {
        ...reconState.ReconfilteredValues,
        dataSource: dataSourceSelected,
      };
      reconDispatch({ type: 'setReconFilteredValues', payload });
    }
  }, []);

  const handleGenerateData = async () => {
    reconDispatch({ type: 'setReconRegion', payload: dataSourceSelected });
    // Mocking data generation based on the selected report
    if (dataSourceSelected) {
      let tradeType = 'PW';
      let transactionType = 'BUY';
      if (dataSourceSelected === 'AM_NG' || dataSourceSelected === 'AU_NG') {
        tradeType = 'NG';
      }

      if (dataSourceSelected === 'EU_GROUP') {
        transactionType = 'NET';
      }

      const payload = {
        dataSource: dataSourceSelected,
        year: [],
        month: [],
        uom: 'TWh',
        transactionType,
        tradeType,
        reportType: 'DELTA_DETAILS',
        pageNumber: 1,
        pageSize: 10,
        ruleApplied: 'YES',
        ncfCategory: [],
      };
      reconDispatch({ type: 'setReconFilteredValues', payload });

      const reconFilterOptionsResponse = await reconFilterOptions({
        dataSource: dataSourceSelected,
      });
      reconDispatch({
        type: 'setReconFilterOptions',
        payload: reconFilterOptionsResponse.data,
      });
    } else {
      setGeneratedData('Please select a report to generate data.');
    }
  };

  return (
    <div data-testid='filter' style={{ width: '60%' }}>
      <Flexbox flexDirection='row' alignItems='flex-start' gap='20px'>
        <PPASelectFormField
          id='report'
          label='Select the Data Source'
          testId='Report'
          value={dataSourceSelected}
          onChange={setDataSourceSelected}
          options={options}
          disabled={options.length === 1}
        />
        <Button
          className='getDataBtn'
          data-testid='Generate Data'
          onClick={handleGenerateData}
          style={{ marginLeft: '10px', marginTop: '24px', width: '162px', border: '1px solid' }}
          disabled={options.length === 1}
        >
          Generate Data
        </Button>
      </Flexbox>
      {generatedData && <div style={{ marginTop: '20px' }}>{generatedData}</div>}
    </div>
  );
};
